import React, { useState, useEffect } from 'react'
import VerInforme from './VerInforme.js'
// import EditarUsuario from './EditarUsuario.js'
import axios from 'axios'

const InformeAdmin = ({ baseUrl, Titulo, cursos }) => {

	//almacena toda la data de categorias 
	const [tituloPag, setTituloPag] = useState(Titulo)
	const [data, setData] = useState([])
	const [funcion, setFuncion] = useState('cargando')
	const [cargando, setCargando] = useState(true)

	const peticionTraeDatos = async () => {
		await axios.get(baseUrl, { params: { usuario: '1', clave: '1', unico: 'false' } })
			.then(response => {
				setData(response.data)
				setCargando(false)

				setFuncion('ver')
			}).catch(error => {
				console.log(error)
			})
	}

	//Trae todas las categorias de la api
	useEffect(() => { peticionTraeDatos() }, [])

	useEffect(() => {
		switch (funcion) {
			case 'ver': return setTituloPag('Ver Informe de Usuarios')
			default: return setTituloPag('Usuarios')
		}
	}, [funcion])

	return (
		<div>
			<p></p>
			<div className="row">
				<div className="col-md-12">
					{funcion === 'cargando' ?
						<div className="loader">Loading...</div>
						: null}
					{funcion === 'ver' ?
						<VerInforme
							data={data}
							cargando={cargando}
						/>
						: null}
				</div>
			</div>
		</div>
	)
}

export default InformeAdmin